import React from "react";
import { Button, Space, TreeSelect } from "antd";
import { FiPlus } from "react-icons/fi";
import styled from "styled-components";
import { themes } from "../config";
import { useSelector } from "react-redux";
import { convertToTreeData } from "../helpers/utils";

const ColumnsListBtn = styled(Space)`
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 1;
  .columnsList-btn {
    width: 55px;
    height: 55px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
  }

  .add-column-btn-wrapper {
    position: absolute;
    bottom: -262px;
    left: auto;
    right: 0;
    width: 252px;
    box-shadow: 0 -10px 10px rgba(0, 0, 0, 0.024);
    background: ${() =>
      themes[useSelector((state) => state.theme.name)].components.Menu
        .headerBgColor};
    backdrop-filter: blur(12px);
    .add-column-btn {
      padding: 22px 15px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0 0 8px 8px;
    }
    opacity: 0;
    transition: opacity 0.1s ease-in;
    animation: fadeIn 0.1s ease-in forwards;
    animation-delay: 0.1s;
  }

  .tree-select-container {
    position: relative;
    .ant-select-show-arrow {
      opacity: 0;
      pointer-events: none;
    }
    .ant-tree {
      width: 280px;
      max-height: 400px;
      overflow: auto;
      border: 1px solid
        ${() =>
          themes[useSelector((state) => state.theme.name)].components
            .HeaderButtonsStyle.backButtonBorder};
      border-radius: 8px;
      padding: 0;
      box-shadow: 0 0 20px rgba(0, 133, 202, 0.24);
      position: relative;
      top: -10px;
      .ant-tree-treenode {
        width: 100%;
      }
      .ant-tree-switcher-noop {
        display: none;
      }
      .ant-tree-node-content-wrapper {
        flex-shrink: 0;
        width: 100%;
        padding: 0px 14px;
        line-height: 3;
      }
      .ant-tree-list-holder-inner {
        padding: 8px 8px 70px;
      }
      .ant-tree-node-selected {
        background: ${() =>
          themes[useSelector((state) => state.theme.name)].components.Menu
            .itemSelectedBg};
        color: ${() =>
          themes[useSelector((state) => state.theme.name)].components.Menu
            .itemClr};
      }
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const SelectTableColumns = ({
  selectedColumns,
  setSelectedColumns,
  columnOptions,
  handleColumnsAdd,
  selectOpen,
  setSelectOpen,
}) => {
  // const handleClickOutside = (event) => {
  //   if (
  //     selectOpen &&
  //     !event.target.closest(".tree-select-container") &&
  //     !event.target.closest(".columnsList-btn")
  //   ) {
  //     setSelectOpen(false);
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [selectOpen]);

  return (
    <ColumnsListBtn direction="vertical" align="end">
      <div
        className="columnsList-btn"
        onClick={() => setSelectOpen((prev) => !prev)}
      >
        <FiPlus />
      </div>
      <div className="tree-select-container ">
        {selectOpen && (
          <TreeSelect
            defaultValue={selectedColumns?.map((c) => c?.value)}
            value={selectedColumns?.map((c) => c?.value)}
            open={selectOpen}
            multiple
            showSearch
            dropdownStyle={{ maxHeight: 400, overflow: "auto", width: "250px" }}
            placeholder="Select Group"
            allowClear
            onSelect={(_, selectedNode) => {
              setSelectedColumns((prev) => {
                return [
                  ...prev,
                  {
                    title: selectedNode?.title,
                    value: selectedNode?.value,
                  },
                ];
              });
            }}
            onDeselect={(_, selectedNode) => {
              setSelectedColumns((prev) => {
                return prev.filter(
                  (item) => item?.value !== selectedNode?.value,
                );
              });
            }}
            treeData={convertToTreeData(columnOptions, "tree-columns")}
          />
        )}
        {selectOpen && (
          <div className="add-column-btn-wrapper">
            <Button
              className="border border-solid bg-btn-dark text-white font-medium | add-column-btn"
              onClick={handleColumnsAdd}
            >
              Update Columns
            </Button>
          </div>
        )}
      </div>
    </ColumnsListBtn>
  );
};

export default SelectTableColumns;
