import { Button, Input, Modal, notification, Progress } from "antd";
import { Upload } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { FiLink2 } from "react-icons/fi";
import { MdOutlineFileUpload } from "react-icons/md";
import styled from "styled-components";
import { store } from "../../../Redux/store";
import axiosInstance from "../../../helpers/axios";
import Apis from "../../../helpers/Apis";
import { ReloadOutlined } from "@ant-design/icons";
import { IoImageOutline } from "react-icons/io5";
// import { FaRegFileLines, FaRegFilePdf } from "react-icons/fa6";
// import { BsFiletypeJpg, BsFiletypePng, BsFiletypeSvg } from "react-icons/bs";
// import { BiMoviePlay } from "react-icons/bi";

const FileDragIcon = styled(MdOutlineFileUpload)`
  width: 30px;
  height: 30px;
`;

const CustomDragger = ({ iFace, setIsModalOpen, updateFilesList, folder }) => {
  const { Dragger } = Upload;
  const [isUrlModalOpen, setIsUrlModalOpen] = useState(false);
  const urlRef = useRef("");
  const [files, setFiles] = useState({});

  const handleChange = async (info) => {
    const fileDetails = {
      name: info.file.name,
      type: info.file.type,
      uid: info.file.uid,
    };
    try {
      const formData = new FormData();
      formData.append("file", info?.file);
      formData.append("folder", folder ? folder : "");
      const resp1 = await axiosInstance.post(Apis.UPLOAD_FILE(), formData, {
        headers: {
          "x-tenant-id": store.getState().user.userId,
        },
        onUploadProgress: (progressEvent) => {
          setFiles((pre) => ({
            ...pre,
            [fileDetails.uid]: {
              ...fileDetails,
              progress: progressEvent.progress,
              status: "active",
              isProcessed: false,
            },
          }));
        },
      });
      if (resp1.status === 200) {
        setFiles((pre) => ({
          ...pre,
          [fileDetails.uid]: {
            ...pre[fileDetails.uid],
            ...(resp1?.data?.payload?.data || {}),
            status: "success",
            isProcessed: true,
          },
        }));
      }
    } catch (error) {
      console.log("error", error);
      notification.error({ message: `${fileDetails.name} upload failed.` });
      setFiles((pre) => ({
        ...pre,
        [fileDetails.uid]: {
          ...pre[fileDetails.uid],
          status: "exception",
          isProcessed: true,
        },
      }));
    }
  };

  useEffect(() => {
    if (Object.keys(files).length) {
      const isEveryFileProcessed = Object.keys(files).some(
        (key) => !files[key].isProcessed,
      );
      if (!isEveryFileProcessed) {
        updateFilesList(
          Object.keys(files).map((file) => ({
            ...files[file],
          })),
        );
        setFiles([]);
        setIsModalOpen(false);
      }
    }
  }, [files]);

  const handleImportUrl = () => {
    setIsUrlModalOpen(false);
    console.log("Url", urlRef.current.input.value);
  };
  return (
    <div>
      <Dragger
        name="file"
        multiple={iFace === "files" || iFace === "files-page"}
        customRequest={handleChange}
        showUploadList={false}
        accept={
          iFace === "file-image"
            ? ".png, .jpg, .jpeg, image/png, image/jpeg"
            : "*"
        }
      >
        <p className="flex justify-center gap-3 mb-3">
          <FileDragIcon />
          <Button
            type="primary"
            onClick={(e) => {
              e.stopPropagation();
              setIsModalOpen(false), setIsUrlModalOpen(true);
            }}
            shape="circle"
            icon={<FiLink2 size={24} />}
          />
        </p>
        <p className="ant-upload-text">Drag & Drop a File Here</p>
        <div className="max-h-[500px] overflow-auto custom-scrollbar">
          {Object.keys(files).length > 0 &&
            Object.keys(files).map((key, index) => (
              <div
                key={index}
                className="flex items-center gap-3 p-3 mt-4 border border-solid rounded-lg border-[#d4d4d4]"
              >
                <div>
                  <IoImageOutline size={26} />
                  {/* <FaRegFileLines size={24} />
                <BiMoviePlay size={26} /> */}
                </div>
                <div key={key} className="mr-2 grow text-start">
                  <p className="text-wrap -mb-1">{files[key].name}</p>
                  <Progress
                    percent={
                      files[key].status === "success"
                        ? 100
                        : Math.floor(files[key].progress * 100)
                    }
                    status={files[key].status}
                    size="small"
                  />
                </div>
                <Button
                  shape="circle"
                  size="small"
                  color="danger"
                  icon={<ReloadOutlined size={16} />}
                />
              </div>
            ))}
        </div>
      </Dragger>

      <Modal
        title="Import File from URL"
        open={isUrlModalOpen}
        onOk={handleImportUrl}
        onCancel={() => setIsUrlModalOpen(false)}
        destroyOnClose
      >
        <p className="mb-3">
          <Input
            ref={urlRef}
            placeholder="URL"
            className="min-h-14 rounded-xl placeholder:font-normal px-6"
          />
        </p>
      </Modal>
    </div>
  );
};

export default CustomDragger;
