import React, { useEffect, useId, useState } from "react";
import {
  Form,
  Row,
  Col,
  Input,
  Select,
  Checkbox,
  Divider,
  Button,
  InputNumber,
  notification,
  TreeSelect,
} from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { themes } from "../../../config";
import { useParams } from "react-router-dom";
import axiosInstance from "../../../helpers/axios";
import Apis from "../../../helpers/Apis";
import { store } from "../../../Redux/store";
import { convertToTreeData } from "../../../helpers/utils";

const FormStyling = styled(Form)`
  background-color: ${() =>
    themes[useSelector((state) => state.theme.name)].token.colorGrayrBg};
  border-top: 1px solid
    ${() => themes[useSelector((state) => state.theme.name)].token.grayClr};
  border-bottom: 1px solid
    ${() => themes[useSelector((state) => state.theme.name)].token.grayClr};
  padding: 34px;
`;

const InvertedCheckBox = styled(Checkbox)`
  border-radius: 12px;
  border: 1px solid
    ${() =>
      themes[useSelector((state) => state.theme.name)].components.Input
        .colorBorder};
  background: ${() =>
    themes[useSelector((state) => state.theme.name)].components.Input
      .colorBgContainer};
  padding: 1rem;
  width: 100%;
  font-weight: 400;
`;

const OneToMany = ({
  activeTab,
  addField,
  setIsDrawerOpen,
  dataSource,
  setActiveTab,
}) => {
  const { t } = useTranslation();
  const id = useId();
  const [form] = Form.useForm();
  const params = useParams();
  const [collectionsList, setCollectionList] = useState([]);
  const [selectedFields, setSelectedFields] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);

  useEffect(() => {
    getCollectionsList();
  }, []);

  const getCollectionsList = async () => {
    try {
      const resp1 = await axiosInstance.get(Apis.GET_ALL_SCHEMAS({}), {
        headers: {
          "x-tenant-id": store.getState().user.userId,
        },
      });
      if (resp1.status === 200)
        setCollectionList(resp1.data.payload.data?.filter((c) => c?.visible));
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleSubmit = async () => {
    let isError;
    try {
      const values = await form.validateFields();
      isError = dataSource?.fields?.some((f) => f?.field === values.name);
      if (isError) {
        notification.error({ message: "Please enter unique key" });
        return;
      }

      let field = {
        schema_id: params.field,
        field: values.name,
        type: values.type,
        path: selectedGroup?.value
          ? selectedGroup?.value + "." + values?.name
          : values?.name,
        field_type: selectedGroup ? "Object" : "Array",
        ref: values?.foreign_key_table,
        meta: {
          field: values.name,
          interface: activeTab,
          width: "full",
          sort: dataSource?.fields?.length + 1,
          nullable: true,
          options: {
            layout: values.layout,
            creating_items: values.creating_items,
            selecting_items: values.selecting_items,
            display_template: values.display_template,
            per_page: values.per_page,
            item_link: values.item_link,
          },
          hidden: false,
          required: !!values.required,
        },
        schema_definition: {
          name: values.name,
          type: values.type,
          // default: values.default,
          foreign_key_column: values.foreign_key_column,
          foreign_key_table: values.foreign_key_table,
          foreign_key_table_id: collectionsList?.find(
            (c) => c?.collection_id === values.foreign_key_table,
          )?._id,
        },
      };
      addField(field);
      await form.resetFields();
      setActiveTab(null);
    } catch (error) {
      console.log("error", error);
      notification.error({ message: "Something went wrong" });
      isError = true;
    } finally {
      !isError && setIsDrawerOpen(false);
      !isError && setSelectedFields([]);
    }
  };

  const onCollectionChange = async (e) => {
    try {
      const resp = await axiosInstance.get(
        Apis.GET_ALL_FIELDS(
          collectionsList?.find((c) => c?.collection_id === e)?._id,
        ),
        {
          headers: {
            "x-tenant-id": store.getState().user.userId,
          },
        },
      );
      if (resp.status === 200) setSelectedFields(resp.data.payload.data);
    } catch (error) {
      console.log("Error", error);
    }
  };

  return (
    <FormStyling
      layout="vertical"
      className="mt-12"
      form={form}
      initialValues={{
        type: "Alias",
        creating_items: true,
        selecting_items: true,
      }}
    >
      <Row gutter={28}>
        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("Key")}
            className="font-bold"
            htmlFor={`${id}-key`}
            name={`name`}
            rules={[
              {
                required: true,
                max: 50,
              },
            ]}
          >
            <Input
              placeholder={t("A unique common name")}
              onChange={(e) => {
                form.setFieldValue(
                  "name",
                  e.target.value
                    .replace(/[^a-zA-Z0-9_ ]/g, "")
                    .replace(/ /g, "_")
                    ?.toLowerCase(),
                );
              }}
              id={`${id}-key`}
              className="min-h-14 rounded-xl placeholder:font-normal px-6"
            />
          </Form.Item>
        </Col>

        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("Group")}
            className="font-bold"
            htmlFor={`${id}-group`}
          >
            <TreeSelect
              id={`${id}-group`}
              showSearch
              dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
              placeholder="Select Group"
              allowClear
              treeDefaultExpandAll
              onSelect={(_, e) => setSelectedGroup(e)}
              treeData={convertToTreeData(
                dataSource?.fields?.filter((f) => {
                  return ["Object"]?.includes(f?.type);
                }),
                "tree-select",
              )}
            />
          </Form.Item>
        </Col>

        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("type")}
            htmlFor={`${id}-type`}
            className="font-bold"
            rules={[{ required: true }]}
            name={`type`}
          >
            <Select
              size="large"
              style={{ flex: 1, height: "56px" }}
              id={`${id}-type`}
              disabled={true}
              options={[
                {
                  value: "Alias",
                  label: "Alias",
                },
              ]}
            />
          </Form.Item>
        </Col>

        {/* <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("Default Value")}
            htmlFor={`${id}-null`}
            className="font-bold"
            name={`default`}
          >
            <Input
              placeholder={t("NULL")}
              id={`${id}-null`}
              className="min-h-14 rounded-xl placeholder:font-normal px-6"
            />
          </Form.Item>
        </Col> */}

        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("Required")}
            htmlFor={`${id}-requireValue`}
            className="font-bold"
            name={`required`}
            valuePropName="checked"
          >
            <InvertedCheckBox id={`${id}-requireValue`} defaultChecked={false}>
              Require value to be set on creation
            </InvertedCheckBox>
          </Form.Item>
        </Col>

        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("Layout")}
            htmlFor={`${id}-layout`}
            className="font-bold"
            name={`layout`}
          >
            <Select
              showSearch
              size="large"
              style={{ flex: 1, height: "56px" }}
              id={`${id}-layout`}
              options={[
                {
                  value: "List",
                  label: "List",
                },
                {
                  value: "Table",
                  label: "Table",
                },
              ]}
            />
          </Form.Item>
        </Col>

        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("Related Collection")}
            className="font-bold"
            htmlFor={`${id}-related-collection`}
            rules={[
              {
                required: true,
                message: "Please Enter Collection!",
              },
            ]}
            name={`foreign_key_table`}
          >
            <Select
              showSearch
              size="large"
              style={{ flex: 1, height: "56px" }}
              id={`${id}-related-collection`}
              options={collectionsList
                ?.filter((c) => c?._id !== params.field)
                ?.map((c) => {
                  return { label: c?.collection_id, value: c?.collection_id };
                })}
              onChange={onCollectionChange}
            />
          </Form.Item>
        </Col>

        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("Foreign Key")}
            className="font-bold"
            htmlFor={`${id}-foreign-key`}
            rules={[
              {
                required: true,
                message: "Please Enter Foreign Key!",
              },
            ]}
            name={`foreign_key_column`}
          >
            <Select
              showSearch
              size="large"
              style={{ flex: 1, height: "56px" }}
              id={`${id}-foreign-key`}
              options={selectedFields?.map((c) => {
                return {
                  label: c?.field,
                  value: c?.field,
                  disabled: c?.field === "_id",
                };
              })}
            />
          </Form.Item>
        </Col>

        <Col className="gutter-row" xs={24}>
          <Divider className="mt-3 mb-6" />
        </Col>

        <Col className="gutter-row" xs={24} md={24}>
          <Form.Item
            label={t("Display Template")}
            className="font-bold"
            htmlFor={`${id}-display-template`}
            name={`display_template`}
          >
            <Select
              showSearch
              size="large"
              style={{ flex: 1, height: "56px" }}
              id={`${id}-display-template`}
              options={selectedFields?.map((c) => {
                return { label: c?.field, value: c?.field };
              })}
            />
          </Form.Item>
        </Col>

        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("Creating Items")}
            htmlFor={`${id}-create-item`}
            className="font-bold"
            name={`creating_items`}
            valuePropName="checked"
          >
            <InvertedCheckBox id={`${id}-create-item`} checked>
              Enable Create Button
            </InvertedCheckBox>
          </Form.Item>
        </Col>

        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("Selecting Items")}
            htmlFor={`${id}-select-value`}
            className="font-bold"
            name={`selecting_items`}
            valuePropName="checked"
          >
            <InvertedCheckBox id={`${id}-select-value`} checked>
              Enable Select Button
            </InvertedCheckBox>
          </Form.Item>
        </Col>

        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("Per Page")}
            htmlFor={`${id}-per-page`}
            className="font-bold"
            name={`per_page`}
          >
            <InputNumber
              placeholder={"Items Shown"}
              id={`${id}-per-page`}
              className="min-h-14 flex items-center rounded-xl placeholder:font-normal px-3 w-full leading-10 overflow-hidden"
            />
          </Form.Item>
        </Col>

        {/* <Col className="gutter-row" xs={24} md={24}>
          <Form.Item
            label={t("Filter")}
            htmlFor={`${id}-filter`}
            className="font-bold"
          >
            <Input
              placeholder={t("No configured rules")}
              id={`${id}-filter`}
              className="min-h-14 rounded-xl placeholder:font-normal px-6"
            />
            <Button type="link">
              Add Filter <FaPlus className="inline-block align-middle ms-1" />
            </Button>
          </Form.Item>
        </Col> */}

        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("Item link")}
            htmlFor={`${id}-link-item`}
            className="font-bold"
            name={`item_link`}
            valuePropName="checked"
          >
            <InvertedCheckBox id={`${id}-link-item`}>
              Show a link to the item
            </InvertedCheckBox>
          </Form.Item>
        </Col>

        <Col className="gutter-row" xs={24}>
          <Button
            onClick={handleSubmit}
            className="rounded-3xl bg-btn-dark text-white px-5 py-3 mt-4 font-medium h-auto w-full"
          >
            Save
          </Button>
          <Button type="link" className="mt-4 font-medium h-auto w-full">
            Continue in Advanced Field Creation Mode
          </Button>
        </Col>
      </Row>
    </FormStyling>
  );
};

export default OneToMany;
