/* eslint-disable react/react-in-jsx-scope */
import { Navigate } from "react-router-dom";

import Dashboard from "../Pages/Dashboard";
import UserProfile from "../Pages/Authentication/UserProfile";
import CollectionsList from "../Pages/Collection/List";
import Role from "../Pages/Role";
import CollectionView from "../Pages/Collection/View";
import CollectionMapping from "../Pages/Collection/View/Mapping";
import Content from "../Pages/Content/Content";
import Media from "../Pages/MediaLibrary/Media";
// import Insight from "../Pages/Insights/Insight";
import Help from "../Pages/Help/Help";
import Instellingen from "../Pages/Instellingen/Instellingen";
import ChangeDataModalCollection from "../Pages/Instellingen/ChangeDataModalCollection";
import Gebruikers from "../Pages/Gebruikers/Gebruikers";
import TenantList from "../Pages/Tenant/TenantList";
import Permissions from "../Pages/Instellingen/Permissions";

const authProtectedRoutes = [
  // ======= Dashboard =========
  { path: "*", component: <Navigate to="/dashboard" /> },
  { path: "/", exact: true, component: <Navigate to="/dashboard" /> },
  { path: "/dashboard", component: <Dashboard /> },

  // ======= Content =========
  {
    path: "/content/:table?/:action?/:id?",
    exact: true,
    component: <Content />,
  },

  // ======= Gebruikers =========
  { path: "/gebruikers", exact: true, component: <Gebruikers /> },
  { path: "/gebruikers/:pages", exact: true, component: <Gebruikers /> },

  // ======= Media library =========
  { path: "/media-library", exact: true, component: <Media /> },
  { path: "/media-library/:folderId", exact: true, component: <Media /> },

  // // ======= Insights =========
  // { path: "/insights", exact: true, component: <Insight /> },

  // ======= App Guide / help =========
  { path: "/help", exact: true, component: <Help /> },

  // ======= Instellingen =========
  { path: "/instellingen", exact: true, component: <Instellingen /> },
  { path: "/instellingen/:pages", exact: true, component: <Instellingen /> },
  {
    path: "/instellingen/:pages/:page",
    exact: true,
    component: <Instellingen />,
  },

  // ======= Instellingen Sub Pages =========
  {
    path: "/instellingen/toegangscontrole/permissisons/:roleId",
    exact: true,
    component: <Permissions />,
  },
  {
    path: "/instellingen/data-model-change-collection/:field",
    exact: true,
    component: <ChangeDataModalCollection />,
  },

  {
    path: "/collection/:code/mapping",
    exact: true,
    component: <CollectionMapping />,
  },
  { path: "/collections/:page?", exact: true, component: <CollectionsList /> },
  { path: "/roles/:page?", exact: true, component: <Role /> },
  {
    path: "/collection/:code/:page?",
    exact: true,
    component: <CollectionView />,
  },

  { path: "/tenant-list", exact: true, component: <TenantList /> },

  // user profile
  { path: "/user-profile", component: <UserProfile /> },
];

const publicRoutes = [
  // Authentication
];

export { publicRoutes, authProtectedRoutes };
