import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedFolderData: "",
};

const mediaSlice = createSlice({
  name: "media",
  initialState,
  reducers: {
    setSelectedFolderData: (state, action) => {
      state.selectedFolderData = action.payload;
    },
  },
});

export const { setSelectedFolderData } = mediaSlice.actions;
export default mediaSlice.reducer;
