import React from "react";
import styled from "styled-components";
import { Button, Flex, Input } from "antd";
import { CiSearch } from "react-icons/ci";
import { FiFolderPlus, FiPlus } from "react-icons/fi";
import { IoFilter, IoArchiveOutline } from "react-icons/io5";
import { RiDeleteBin6Line, RiUserAddLine } from "react-icons/ri";
import { BsCheckLg } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { themes } from "../../config";
import { useSelector } from "react-redux";
import { CloseOutlined } from "@ant-design/icons";

const HeaderInputStyle = styled(Input)`
  min-height: 56px;
  border-radius: 100vh;
  padding: 13px 24px;

  > .ant-input-prefix {
    margin-inline-end: 10px;
  }
`;

const HeaderButton = styled(Button)`
  min-width: 56px !important;
  width: 56px !important;
  height: 56px;
  border: transparent;
  display: flex;
  align-items: center;
  justify-content: center;

  &.folder-button {
    background: ${() =>
      themes[useSelector((state) => state.theme.name)].components
        .HeaderButtonsStyle.folderButtonBg};
    color: ${() =>
      themes[useSelector((state) => state.theme.name)].components
        .HeaderButtonsStyle.folderButtonClr};

    &:hover {
      background: ${() =>
        themes[useSelector((state) => state.theme.name)].components
          .HeaderButtonsStyle.folderButtonHoverBg} !important;
      color: ${() =>
        themes[useSelector((state) => state.theme.name)].components
          .HeaderButtonsStyle.folderButtonHoverClr}!important;
    }
  }
  &.add-button {
    background: ${() =>
      themes[useSelector((state) => state.theme.name)].components
        .HeaderButtonsStyle.addButtonBg};
    color: ${() =>
      themes[useSelector((state) => state.theme.name)].components
        .HeaderButtonsStyle.addButtonClr};

    &:hover {
      background: ${() =>
        themes[useSelector((state) => state.theme.name)].components
          .HeaderButtonsStyle.addButtonHoverBg} !important;
      color: ${() =>
        themes[useSelector((state) => state.theme.name)].components
          .HeaderButtonsStyle.addButtonHoverClr} !important;
    }
  }

  &.delete-button {
    background: ${() =>
      themes[useSelector((state) => state.theme.name)].components
        .HeaderButtonsStyle.deleteButtonBg};
    color: ${() =>
      themes[useSelector((state) => state.theme.name)].components
        .HeaderButtonsStyle.deleteButtonClr};

    &:hover {
      background: ${() =>
        themes[useSelector((state) => state.theme.name)].components
          .HeaderButtonsStyle.deleteButtonHoverBg} !important;
      color: ${() =>
        themes[useSelector((state) => state.theme.name)].components
          .HeaderButtonsStyle.deleteButtonHoverClr} !important;
    }
  }

  &.submit-button {
    background: ${() =>
      themes[useSelector((state) => state.theme.name)].components
        .HeaderButtonsStyle.submitButtonBg};
    color: ${() =>
      themes[useSelector((state) => state.theme.name)].components
        .HeaderButtonsStyle.submitButtonClr};

    &:hover {
      background: ${() =>
        themes[useSelector((state) => state.theme.name)].components
          .HeaderButtonsStyle.submitButtonHoverBg} !important;
      color: ${() =>
        themes[useSelector((state) => state.theme.name)].components
          .HeaderButtonsStyle.submitButtonHoverClr} !important;
    }
  }

  &.archive-button {
    background: ${() =>
      themes[useSelector((state) => state.theme.name)].components
        .HeaderButtonsStyle.archiveButtonBg};
    color: ${() =>
      themes[useSelector((state) => state.theme.name)].components
        .HeaderButtonsStyle.archiveButtonClr};

    &:hover {
      background: ${() =>
        themes[useSelector((state) => state.theme.name)].components
          .HeaderButtonsStyle.archiveButtonHoverBg} !important;
      color: ${() =>
        themes[useSelector((state) => state.theme.name)].components
          .HeaderButtonsStyle.archiveButtonHoverClr} !important;
    }
  }

  &.disabled-submit-button {
    cursor: not-allowed;
    background: ${() =>
      themes[useSelector((state) => state.theme.name)].components
        .HeaderButtonsStyle.disabledSubmitButtonBg};
    color: ${() =>
      themes[useSelector((state) => state.theme.name)].components
        .HeaderButtonsStyle.disabledSubmitButtonClr};

    &:hover {
      background: ${() =>
        themes[useSelector((state) => state.theme.name)].components
          .HeaderButtonsStyle.disabledSubmitButtonHoverBg} !important;
      color: ${() =>
        themes[useSelector((state) => state.theme.name)].components
          .HeaderButtonsStyle.disabledSubmitButtonHoverClr} !important;
    }
  }
`;

const RightSIdeTitlePart = ({
  isFilterShow,
  folderButton,
  handleFolderClick,
  // setOpen,
  addButton,
  userAdd,
  deleteButton,
  handleDeleteClick,
  showButton,
  archiveButton,
  disabledSubmitButton,
  isDrawerOpen,
  setIsDrawerOpen,
  handleShowClick,
  handleAddClick,
  showDisabled,
  addDisabled,
  deleteDisabled,
  searchTerm,
  setSearchTerm,
}) => {
  const { t } = useTranslation();
  return (
    <Flex className="flex items-center justify-start md:justify-center gap-3 mt-3 lg:mt-0">
      {isFilterShow && (
        <HeaderInputStyle
          size="large"
          placeholder={
            t("search") + " " + t("in") + " " + t("collection").toLowerCase()
          }
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          prefix={<CiSearch size={20} />}
          suffix={
            <>
              {searchTerm && (
                <CloseOutlined onClick={() => setSearchTerm("")} size={20} />
              )}
              <IoFilter size={20} />
            </>
          }
        />
      )}
      {folderButton && (
        <HeaderButton
          shape="circle"
          icon={<FiFolderPlus size={24} />}
          className="btn folder-button"
          onClick={handleFolderClick}
        />
      )}
      {userAdd && (
        <HeaderButton
          shape="circle"
          icon={<RiUserAddLine size={24} />}
          className="btn folder-button"
        />
      )}
      {addButton && (
        <HeaderButton
          shape="circle"
          icon={<FiPlus size={24} />}
          className="btn add-button"
          onClick={() => {
            if (setIsDrawerOpen) setIsDrawerOpen(!isDrawerOpen);
            if (handleAddClick) handleAddClick();
          }}
          disabled={addDisabled}
        />
      )}
      {deleteButton && (
        <HeaderButton
          shape="circle"
          icon={<RiDeleteBin6Line size={24} />}
          className="btn delete-button"
          onClick={handleDeleteClick}
          disabled={deleteDisabled}
        />
      )}
      {showButton && (
        <HeaderButton
          shape="circle"
          icon={<BsCheckLg size={24} />}
          className="btn submit-button"
          onClick={handleShowClick}
          disabled={showDisabled}
        />
      )}
      {archiveButton && (
        <HeaderButton
          shape="circle"
          icon={<IoArchiveOutline size={24} />}
          className="btn archive-button"
        />
      )}
      {disabledSubmitButton && (
        <HeaderButton
          shape="circle"
          icon={<BsCheckLg size={24} />}
          className="btn disabled-submit-button dark:bg-inputField"
        />
      )}
    </Flex>
  );
};

export default RightSIdeTitlePart;
