import React, { useId, useState } from "react";
import {
  Form,
  Row,
  Col,
  Input,
  Select,
  Checkbox,
  Divider,
  Button,
  Flex,
  ColorPicker,
  notification,
  Drawer,
  Radio,
  TreeSelect,
} from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { themes } from "../../../config";
import { BsInfoCircle } from "react-icons/bs";
import { useParams } from "react-router-dom";
import CreateChoices from "./CreateChoices";
import { DndContext } from "@dnd-kit/core";
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { TableSort } from "../../../Pages/Instellingen/ChangeDataModalCollection";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { MenuOutlined } from "@ant-design/icons";
import { CSS } from "@dnd-kit/utilities";
import { AiOutlineClose } from "react-icons/ai";
import { convertToTreeData } from "../../../helpers/utils";

const FormStyling = styled(Form)`
  background-color: ${() =>
    themes[useSelector((state) => state.theme.name)].token.colorGrayrBg};
  border-top: 1px solid
    ${() => themes[useSelector((state) => state.theme.name)].token.grayClr};
  border-bottom: 1px solid
    ${() => themes[useSelector((state) => state.theme.name)].token.grayClr};
  padding: 34px;
`;

const NotHasField = styled(Flex)`
  background-color: ${() =>
    themes[useSelector((state) => state.theme.name)].token.NotHasFieldClr};
  border-left: 6px solid
    ${() => themes[useSelector((state) => state.theme.name)].token.brandColor};
  padding-inline: 15px;
  border-radius: 6px;
  min-height: 56px;
`;

const DrawerStyle = styled(Drawer)`
  .ant-drawer-header {
    padding: 0;
    border: unset !important;
    .ant-drawer-header-title {
      .ant-drawer-close {
        width: 56px;
        height: 56px;
        position: absolute;
        left: -65px;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 30px;
        background: white;
        border-radius: 100vh;
        box-shadow: 0px 4px 12px 0px rgba(8, 24, 44, 0.16);
        @media screen and (max-width: 991px) {
          left: calc(50% - 28px);
          bottom: 1rem;
          top: auto;
        }
      }
    }
  }
  .ant-drawer-body {
    padding: 0;
    .ant-tabs-nav {
      padding-top: 40px;
      width: min(100%, 200px);
      min-height: 100vh;
      background-color: #f5f6f7;
      .ant-tabs-nav-wrap {
        &:before,
        &:after,
        .ant-tabs-ink-bar {
          display: none;
        }
        .ant-tabs-nav-list {
          padding-inline: 10px;
          .ant-tabs-tab {
            border-radius: 8px;
            margin-top: 5px;
            > div {
              color: #000 !important;
              font-size: 16px;
              font-style: normal;
            }
            &.ant-tabs-tab-active {
              background-color: #ebeced;
            }
          }
        }
      }
    }

    .ant-tabs-content-holder {
      padding-top: 40px;
      .ant-tabs-tabpane {
        padding-right: 24px;
      }
    }
  }
`;

const InvertedCheckBox = styled(Checkbox)`
  border-radius: 12px;
  border: 1px solid
    ${() =>
      themes[useSelector((state) => state.theme.name)].components.Input
        .colorBorder};
  background: ${() =>
    themes[useSelector((state) => state.theme.name)].components.Input
      .colorBgContainer};
  padding: 1rem;
  width: 100%;
  font-weight: 400;
`;

export const hasNewFieldFunc = () => {
  return (
    <NotHasField className="items-center gap-3">
      <BsInfoCircle size={20} />
      <p className="font-normal">No items</p>
    </NotHasField>
  );
};

const TableRow = ({ children, ...props }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props["data-row-key"],
  });
  const style = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleY: 1,
      },
    ),
    transition,
    ...(isDragging
      ? {
          position: "relative",
          zIndex: 9999,
        }
      : {}),
  };
  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if (child.key === "sort") {
          return React.cloneElement(child, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{
                  touchAction: "none",
                  cursor: "move",
                }}
                {...listeners}
              />
            ),
          });
        }
        return React.cloneElement(child, { key: child.key || index });
      })}
    </tr>
  );
};

const onDragEnd = ({ active, over }) => {
  if (active.id !== over?.id) {
    setDataSource((previous) => {
      const activeIndex = previous.findIndex((i) => i.key === active.id);
      const overIndex = previous.findIndex((i) => i.key === over?.id);
      return arrayMove(previous, activeIndex, overIndex);
    });
  }
};

const RadioButtons = ({
  activeTab,
  addField,
  setIsDrawerOpen,
  dataSource,
  setActiveTab,
}) => {
  const { t } = useTranslation();
  const id = useId();
  const [form] = Form.useForm();
  const params = useParams();
  const [open, setOpen] = useState(false);
  const [radioChoices, setRadioChoices] = useState([]);
  const [activeChoice, setActiveChoice] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);

  const handleSubmit = async () => {
    let isError;
    try {
      const values = await form.validateFields();
      isError = dataSource?.fields?.some((f) => f?.field === values.name);
      if (isError) {
        notification.error({ message: "Please enter unique key" });
        return;
      }
      let field = {
        schema_id: params.field,
        field: values.name,
        type: values.type,
        path: selectedGroup?.value
          ? selectedGroup?.value + "." + values?.name
          : values?.name,
        field_type: selectedGroup ? "Object" : "Single",
        meta: {
          field: values.name,
          interface: activeTab,
          width: "full",
          sort: dataSource?.fields?.length + 1,
          nullable: true,
          options: {
            choices: radioChoices,
            icon_on: values.icon_on,
            icon_off: values.icon_off,
            color: values.color,
            allow_other: values.allow_other,
          },
          hidden: false,
          required: !!values.required,
        },
        schema_definition: {
          name: values.name,
          type: values.type,
          default: values.default,
        },
      };
      addField(field);
      await form.resetFields();
      setActiveTab(null);
    } catch (error) {
      console.log("error", error);
      notification.error({ message: "Something went wrong" });
      isError = true;
    } finally {
      !isError && setIsDrawerOpen(false);
    }
  };

  const columns = [
    {
      key: "sort",
      width: 50,
    },
    {
      dataIndex: "label",
      align: "left",
      width: "auto",
      render: (record, rowData, index) => {
        return (
          <div
            onClick={() => {
              setActiveChoice({ ...rowData, index });
              setOpen(true);
            }}
          >
            {record}
          </div>
        );
      },
    },
    {
      dataIndex: "label",
      key: "delete",
      width: 50,
      render: (record) => {
        return (
          <div
            onClick={() =>
              setRadioChoices((prev) =>
                prev?.filter((f) => f?.label !== record),
              )
            }
          >
            <AiOutlineClose size={24} />
          </div>
        );
      },
    },
  ];

  return (
    <FormStyling
      layout="vertical"
      className="mt-12"
      form={form}
      initialValues={{
        type: "String",
        icon_on: "Manual",
        icon_off: "Manual",
        color: "#1677ff",
      }}
    >
      <Row gutter={28}>
        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("Key")}
            className="font-bold"
            htmlFor={`${id}-key`}
            name={`name`}
            rules={[
              {
                required: true,
                max: 50,
              },
            ]}
          >
            <Input
              placeholder={t("A unique common name")}
              onChange={(e) => {
                form.setFieldValue(
                  "name",
                  e.target.value
                    .replace(/[^a-zA-Z0-9_ ]/g, "")
                    .replace(/ /g, "_")
                    ?.toLowerCase(),
                );
              }}
              id={`${id}-key`}
              className="min-h-14 rounded-xl placeholder:font-normal px-6"
            />
          </Form.Item>
        </Col>

        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("Group")}
            className="font-bold"
            htmlFor={`${id}-group`}
          >
            <TreeSelect
              id={`${id}-group`}
              showSearch
              dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
              placeholder="Select Group"
              allowClear
              treeDefaultExpandAll
              onSelect={(_, e) => setSelectedGroup(e)}
              treeData={convertToTreeData(
                dataSource?.fields?.filter((f) => {
                  return ["Object"]?.includes(f?.type);
                }),
                "tree-select",
              )}
            />
          </Form.Item>
        </Col>

        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("type")}
            htmlFor={`${id}-type`}
            className="font-bold"
            rules={[{ required: true }]}
            name={`type`}
          >
            <Select
              size="large"
              style={{ flex: 1, height: "56px" }}
              id={`${id}-type`}
              disabled
              options={[
                {
                  value: "String",
                  label: "String",
                },
              ]}
            />
          </Form.Item>
        </Col>

        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("Allow other")}
            htmlFor={`${id}-other-value`}
            className="font-bold"
            name={`allow_other`}
            valuePropName="checked"
          >
            <InvertedCheckBox id={`${id}-other-value`} defaultChecked={false}>
              Allow Other Values
            </InvertedCheckBox>
          </Form.Item>
        </Col>

        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("Required")}
            htmlFor={`${id}-requireValue`}
            className="font-bold"
            name={`required`}
            valuePropName="checked"
          >
            <InvertedCheckBox id={`${id}-requireValue`} defaultChecked={false}>
              Require value to be set on creation
            </InvertedCheckBox>
          </Form.Item>
        </Col>

        <Col className="gutter-row" xs={24}>
          <Divider className="mt-3 mb-6" />
        </Col>

        <Col className="gutter-row" xs={24}>
          <Form.Item
            label={t("Choices")}
            htmlFor={`${id}-new-field`}
            className="font-bold"
          >
            {!radioChoices?.length && hasNewFieldFunc()}
            <Button
              className="rounded-3xl  border border-solid border-grey-full bg-grey-full text-black px-5 py-3 mt-4 font-medium h-auto"
              onClick={() => setOpen(true)}
            >
              Create New
            </Button>
          </Form.Item>
          {radioChoices?.length > 0 && (
            <DndContext
              modifiers={[restrictToVerticalAxis]}
              onDragEnd={onDragEnd}
            >
              <SortableContext
                // rowKey array
                items={radioChoices?.map((c) => c?.label)}
                strategy={verticalListSortingStrategy}
              >
                <TableSort
                  style={{ width: "100%" }}
                  pagination={false}
                  components={{
                    body: {
                      row: TableRow,
                    },
                  }}
                  rowKey={(record) => record?.label}
                  columns={columns}
                  dataSource={radioChoices}
                  scroll={{
                    x: 300,
                  }}
                />
              </SortableContext>
            </DndContext>
          )}
        </Col>

        <Col className="gutter-row" xs={24}>
          <Form.Item
            label={t("Default Value")}
            htmlFor={`${id}-null`}
            className="font-bold"
            name={`default`}
          >
            <Radio.Group options={radioChoices} />
          </Form.Item>
        </Col>

        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("Icon On")}
            htmlFor={`${id}-sort`}
            className="font-bold"
            name={`icon_on`}
          >
            <Select
              size="large"
              style={{ flex: 1, height: "56px" }}
              id={`${id}-sort`}
              options={[
                {
                  value: "Manual",
                  label: "Manual",
                },
              ]}
            />
          </Form.Item>
        </Col>

        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("Icon Off")}
            htmlFor={`${id}-sort`}
            className="font-bold"
            name={`icon_off`}
          >
            <Select
              size="large"
              style={{ flex: 1, height: "56px" }}
              id={`${id}-sort`}
              options={[
                {
                  value: "Manual",
                  label: "Manual",
                },
              ]}
            />
          </Form.Item>
        </Col>

        <Col className="gutter-row" xs={24} md={12}>
          <Form.Item
            label={t("Color")}
            htmlFor={`${id}-colorOn`}
            className="font-bold"
            name={`color`}
          >
            <ColorPicker
              id={`${id}-colorOn`}
              showText
              className="w-full justify-start px-3 py-4"
              format="hex"
              onChange={(_, hex) => form.setFieldsValue({ color: hex })}
            />
          </Form.Item>
        </Col>

        <Col className="gutter-row" xs={24}>
          <Button
            onClick={handleSubmit}
            className="rounded-3xl bg-btn-dark text-white px-5 py-3 mt-4 font-medium h-auto w-full"
          >
            Save
          </Button>
          <Button type="link" className="mt-4 font-medium h-auto w-full">
            Continue in Advanced Field Creation Mode
          </Button>
        </Col>
      </Row>
      <DrawerStyle
        width={870}
        // title="Basic Drawer"
        onClose={() => {
          setOpen(false);
          setActiveChoice(null);
        }}
        destroyOnClose
        open={open}
      >
        <CreateChoices
          setChoices={setRadioChoices}
          setOpen={setOpen}
          choiceType={"radio"}
          activeChoice={activeChoice}
        />
      </DrawerStyle>
    </FormStyling>
  );
};

export default RadioButtons;
