import React, { useEffect, useState } from "react";
import { BsInfoCircle } from "react-icons/bs";
import { themes } from "../../../config";
import styled from "styled-components";
import { Button, Drawer, Flex, Modal } from "antd";
import { useSelector } from "react-redux";
import Media from "../../../Pages/MediaLibrary/Media";
import FilesList from "./FilesList";
import CustomDragger from "./CustomDragger";

const DrawerStyle = styled(Drawer)`
  .ant-drawer-header {
    padding: 0;
    border: unset !important;
    .ant-drawer-header-title {
      .ant-drawer-close {
        width: 56px;
        height: 56px;
        position: absolute;
        left: -65px;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 30px;
        background: white;
        border-radius: 100vh;
        box-shadow: 0px 4px 12px 0px rgba(8, 24, 44, 0.16);
        @media screen and (max-width: 991px) {
          left: calc(50% - 28px);
          bottom: 1rem;
          top: auto;
        }
      }
    }
  }
  .ant-drawer-body {
    padding: 0;
    .ant-tabs-nav {
      padding-top: 40px;
      width: min(100%, 200px);
      min-height: 100vh;
      background-color: #f5f6f7;
      .ant-tabs-nav-wrap {
        &:before,
        &:after,
        .ant-tabs-ink-bar {
          display: none;
        }
        .ant-tabs-nav-list {
          padding-inline: 10px;
          .ant-tabs-tab {
            border-radius: 8px;
            margin-top: 5px;
            > div {
              color: #000 !important;
              font-size: 16px;
              font-style: normal;
            }
            &.ant-tabs-tab-active {
              background-color: #ebeced;
            }
          }
        }
      }
    }

    .ant-tabs-content-holder {
      padding-top: 40px;
      .ant-tabs-tabpane {
        padding-right: 24px;
      }
    }
  }
`;

const NotHasField = styled(Flex)`
  background-color: ${() =>
    themes[useSelector((state) => state.theme.name)].token.NotHasFieldClr};
  border-left: 6px solid
    ${() => themes[useSelector((state) => state.theme.name)].token.brandColor};
  padding-inline: 15px;
  border-radius: 6px;
  min-height: 56px;
`;

const CustomFiles = ({ item, updateFormData, formData, isValidationError }) => {
  const [files, setFiles] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);

  useEffect(() => {
    setFiles(formData?.[item?.path] ? formData?.[item?.path] : []);
  }, []);

  useEffect(() => {
    updateFormData({
      key: item?.path,
      value:
        item?.meta?.interface === "files"
          ? files?.map((f) => f?._id)
          : files?.length
            ? files[0]?._id
            : null,
    });
  }, [files]);

  const updateFilesList = (newFiles) => {
    setFiles((prev) => {
      return item?.meta?.interface === "files"
        ? [...prev, ...newFiles]
        : newFiles;
    });
  };

  const handleDelete = (img_id) => {
    setFiles((prev) => prev.filter((p) => p?._id !== img_id));
  };

  return (
    <div>
      {!files?.length ? (
        <NotHasField
          className={`items-center gap-3 font-normal rounded-xl ${isValidationError ? "!bg-[tomato]" : ""}`}
        >
          <BsInfoCircle size={20} />
          <p className="font-normal">No items</p>
        </NotHasField>
      ) : (
        files?.map((file) => (
          <FilesList
            key={file?._id}
            file={file}
            iFace={item?.meta?.interface}
            handleDelete={handleDelete}
          />
        ))
      )}
      <div className="mt-4">
        <Button
          type="primary"
          disabled={!!item.meta?.readonly}
          onClick={() => setIsModalOpen(true)}
          className="rounded-full border border-dark bg-btn-dark text-white px-5 py-3 font-medium h-auto mr-3"
        >
          Upload File
        </Button>
        <Button
          type="primary"
          disabled={!!item.meta?.readonly}
          className="rounded-full bg-white border border-grey-900 text-black px-5 py-3 font-medium h-auto"
          onClick={() => setShowDrawer(true)}
        >
          Add Existing
        </Button>
      </div>
      <Modal
        title="Add File"
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
      >
        <CustomDragger
          iFace={item?.meta?.interface}
          setIsModalOpen={setIsModalOpen}
          updateFilesList={updateFilesList}
          folder={item?.meta?.folder}
        />
      </Modal>
      <DrawerStyle
        width={991}
        onClose={() => setShowDrawer(false)}
        open={showDrawer}
        className="table-in-drawer add-existing-file-drawer"
      >
        <Media
          iFace={item?.meta?.interface}
          updateContentFilesList={updateFilesList}
          setShowDrawer={setShowDrawer}
        />
      </DrawerStyle>
    </div>
  );
};

export default CustomFiles;
