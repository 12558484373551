import React from "react";
import UpdateInterface from "./EditFields/UpdateInterface";
import UpdateSchema from "./EditFields/UpdateSchema";
// import InputFieldTabInterface from "./EditFields/InputFieldTabInterface";
// import InputFieldTabDisplay from "./EditFields/InputFieldTabDiaplay";
import UpdateField from "./EditFields/UpdateField";
import UpdateRelationShip from "./EditFields/UpdateRelationship";
import UpdateValidations from "./EditFields/UpdateValidations";
import { DrawerSidebarTabs } from "../../Common/DrawerSidebarTabStyle";

const EditFields = ({
  dataSource,
  activeItem,
  setIsDrawerOpen,
  setActiveItem,
  init,
  isDrawerOpen,
}) => {
  const updateField = async (type, field) => {
    try {
      let selectedField = dataSource?.fields?.find((f) => f.field === field);
      const { _id, ...restOfSelectedField } = selectedField;
      delete restOfSelectedField.__v;
      const payload = {
        ...restOfSelectedField,
        meta: {
          ...restOfSelectedField.meta,
          width: type,
        },
      };

      const resp = await axiosInstance.put(Apis.UPDATE_A_FIELD(_id), payload, {
        headers: {
          "x-tenant-id": store.getState().user.userId,
        },
      });
      if (resp.status === 200) init();
    } catch (error) {
      console.log("error", error);
    }
  };

  const items = [
    {
      key: "1",
      label: "Schema",
      children: (
        <UpdateSchema
          updateField={updateField}
          dataSource={dataSource}
          activeItem={activeItem}
          setIsDrawerOpen={setIsDrawerOpen}
          setActiveItem={setActiveItem}
        />
      ),
    },
    ["list-m2o", "list-m2m", "list-o2m"].includes(
      activeItem?.meta?.interface,
    ) && {
      key: "7",
      label: "Relationship",
      children: (
        <UpdateRelationShip
          updateField={updateField}
          dataSource={dataSource}
          activeItem={activeItem}
          setIsDrawerOpen={setIsDrawerOpen}
          setActiveItem={setActiveItem}
          init={init}
        />
      ),
    },
    !["object"].includes(activeItem?.meta?.interface) && {
      key: "2",
      label: "Field",
      children: (
        <UpdateField
          updateField={updateField}
          dataSource={dataSource}
          activeItem={activeItem}
          setIsDrawerOpen={setIsDrawerOpen}
          setActiveItem={setActiveItem}
          init={init}
        />
      ),
    },
    !["object", "file", "file-image", "files"].includes(
      activeItem?.meta?.interface,
    ) && {
      key: "3",
      label: "Interface",
      children: (
        <UpdateInterface
          updateField={updateField}
          dataSource={dataSource}
          activeItem={activeItem}
          setIsDrawerOpen={setIsDrawerOpen}
          setActiveItem={setActiveItem}
          init={init}
        />
      ),
    },
    // {
    //   key: "4",
    //   label: "Display",
    //   children: <InputFieldTabDisplay />,
    // },
    !["object", "file", "file-image", "files"].includes(
      activeItem?.meta?.interface,
    ) && {
      key: "5",
      label: "Validation",
      children: (
        <UpdateValidations
          updateField={updateField}
          dataSource={dataSource}
          activeItem={activeItem}
          setIsDrawerOpen={setIsDrawerOpen}
          setActiveItem={setActiveItem}
          init={init}
          isDrawerOpen={isDrawerOpen}
        />
      ),
    },
    // {
    //   key: "6",
    //   label: "Conditions",
    //   children: <InputFieldTabInterface />,
    // },
  ];

  return (
    <>
      <DrawerSidebarTabs tabPosition={"left"} items={items} />
    </>
  );
};

export default EditFields;
