import React from "react";
import withRouter from "../Common/withRouter";
import IconsAll from "../Components/IconsAll";
import BrandLogo from "../Assets/images/logo-sm.svg";
import BrandlightLogo from "../Assets/images/logo-sm.svg";
import BrandSmLogo from "../Assets/images/logo-sm.svg";
// import profilePic from "../Assets/images/users/avatar-1.jpg";
import { Avatar, Menu } from "antd";
import {
  StyleSimpleBar,
  StyleBrandLogo,
  StyleSider,
  SidebarDivider,
  SideBarUserProfile,
  SidebarNotification,
} from "../Common/SidebarStyle";
import { Link } from "react-router-dom";
import { GrNotification } from "react-icons/gr";
import { useTranslation } from "react-i18next";
import { themes } from "../config.js";
import { useSelector } from "react-redux";
import { CiLogout } from "react-icons/ci";
import axiosInstance from "../helpers/axios.js";
import Apis from "../helpers/Apis.js";
import { retrieveLoginPrompt } from "../helpers/utils.js";

const SidebarLayout = ({ theme, router }) => {
  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const { t } = useTranslation();
  const userProfile = useSelector((state) => state.user.userProfile);
  const path = location.pathname.split("/");

  const items = [
    getItem(
      <Link to="/dashboard" className="ml-6">
        {t("dashboard")}
      </Link>,
      "dashboard",
      <IconsAll.DashboardIcon strokeColor="" />,
    ),
    null,
    getItem(
      <Link to="/content" className="ml-6">
        {t("content")}
      </Link>,
      "content",
      <IconsAll.ContentIcon strokeColor="" />,
    ),
    getItem(
      <Link
        to={
          useSelector((state) => state.activeTab["gebruikers"])
            ?.redirectionLink ?? `/gebruikers`
        }
        className="ml-6"
      >
        {t("users")}
      </Link>,
      "gebruikers",
      <IconsAll.GebruikersIcon strokeColor="" />,
    ),
    getItem(
      <Link to="/media-library" className="ml-6">
        {t("media")} {t("library")}
      </Link>,
      "MediaLibrary",
      <IconsAll.MediaLibraryIcon strokeColor="" />,
    ),
    // getItem(
    //   <Link to="/insights" className="ml-6">
    //     {t("insights")}
    //   </Link>,
    //   "Insights",
    //   <IconsAll.InsightsIcon strokeColor="" />,
    // ),
    // getItem(
    //   <Link to="/help" className="ml-6">
    //     {t("app")} {t("guide")} / {t("help")}
    //   </Link>,
    //   "Help",
    //   <IconsAll.HelpIcon strokeColor="" />,
    // ),
    getItem(
      <Link to="/instellingen/data-model" className="ml-6">
        {t("setting")}
      </Link>,
      "instellingen",
      <IconsAll.ConfigIcon strokeColor="" />,
    ),
    // getItem(
    //   <Link to="/tenant-list" className="ml-6">
    //     {t("tenant")}
    //   </Link>,
    //   "tenant",
    //   <IconsAll.AuteurIcon strokeColor="" />
    // ),
  ];

  const logoutUser = async () => {
    try {
      const resp = await axiosInstance.post(Apis.LOGOUT(), {});
      if (resp.status === 200) {
        await localStorage.clear();
        retrieveLoginPrompt().then((response) => {
          window.open(response?.data, "_self");
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  return (
    <StyleSider
      id="sidebar-layout"
      width={() =>
        themes[useSelector((state) => state.theme.name)].components.Menu
          .verticalSidebarWidth
      }
      collapsedWidth="64"
      breakpoint="xl"
    >
      <StyleBrandLogo className="company-logo ant-mx-auto">
        <img
          alt="Brand logo"
          src={theme === "dark" ? BrandlightLogo : BrandLogo}
          height={30}
          style={{ lineHeight: "24px", maxWidth: "40px" }}
          className="brand-dark-logo ant-mx-auto"
        />
        <img
          alt="Brand sm logo"
          src={BrandSmLogo}
          height={24}
          style={{ lineHeight: "24px", maxWidth: "40px" }}
          className="brand-sm-logo ant-mx-auto"
        />
      </StyleBrandLogo>
      <StyleSimpleBar>
        <Menu
          selectedKeys={[path[1]]}
          mode="inline"
          theme="light"
          items={items?.filter((item) => {
            if (userProfile?.is_tenant_owner && item?.key === "gebruikers")
              return false;
            else if (!userProfile?.is_tenant_owner && item?.key === "content")
              return false;
            else if (
              !userProfile?.is_tenant_owner &&
              item?.key === "instellingen"
            )
              return false;
            else return true;
          })}
          collapsedWidth="100"
        ></Menu>
      </StyleSimpleBar>

      <div className="flex flex-col justify-center">
        <SidebarNotification
          type="link"
          className="active mx-auto"
          aria-label="Notifiation"
          icon={
            <GrNotification
              size={18}
              className="text-[var(--notification-icon-clr)]"
            />
          }
        />
        <SidebarDivider className="!w-full" />
        <div className="mt-3 mb-5 cursor-pointer text-center" title="Logout">
          <CiLogout
            color="#fff"
            size={24}
            onClick={logoutUser}
            className="mx-auto"
          />
        </div>
        <SideBarUserProfile
          type="link"
          className="!mx-auto !mb-3"
          onClick={() => router.navigate("/user-profile")}
        >
          <Avatar className="!flex items-center justify-center mx-auto">
            {userProfile?.first_name?.length &&
              (userProfile?.first_name[0] || "")?.toUpperCase() +
                ((userProfile?.last_name?.length &&
                  (userProfile?.last_name[0] || "")?.toUpperCase()) ||
                  "")}
          </Avatar>
        </SideBarUserProfile>
      </div>
    </StyleSider>
  );
};

export default withRouter(SidebarLayout);
