import React, { useEffect, useState } from "react";
import axiosInstance from "../../../helpers/axios";
import Apis from "../../../helpers/Apis";
import { store } from "../../../Redux/store";
import { Button, notification } from "antd";
import { IoClose } from "react-icons/io5";

const FilesList = ({ file, iFace, handleDelete }) => {
  const [imageUrl, setImageUrl] = useState(null);

  const getDisplayImage = async (img_id) => {
    if (!["image/jpg", "image/jpeg", "image/png"].includes(file?.type))
      return null;
    try {
      const resp2 = await axiosInstance.get(Apis.GET_DISPLAY_IMAGE(img_id), {
        headers: {
          "x-tenant-id": store.getState().user.userId,
        },
        responseType: "blob",
      });

      if (resp2.status === 200) {
        setImageUrl(URL.createObjectURL(resp2.data));
      }
    } catch (error) {
      console.log("error", error);
      notification.error("Something went wrong while uploading teh file.");
      return null;
    }
  };

  useEffect(() => {
    getDisplayImage(file?._id);
  }, [file?._id]);

  return iFace === "file-image" ? (
    <div key={file?._id} className="image-add-list">
      <img src={imageUrl} alt="TEST" className="added-image" />
      <Button
        className="p-0 border-0 image-add-button"
        onClick={() => handleDelete(file?._id)}
      >
        <IoClose size={24} />
      </Button>
    </div>
  ) : (
    <div key={file?._id} className="file-add-list">
      {imageUrl ? (
        <img src={imageUrl} alt="TEST" className="file-add-list-img" />
      ) : (
        <div className="file-add-list-file-extention">
          {file?.filename_disk?.split(".")?.pop()}
        </div>
      )}
      <div>{file?.title}</div>
      <Button
        className="p-0 border-0 bg-[transparent] hover:bg-[transparent] hover:!text-[rgba(var(--btn-delete),1)]"
        onClick={() => handleDelete(file?._id)}
      >
        <IoClose
          size={24}
          className="hover:bg-[transparent] hover:!text-[rgba(var(--btn-delete),1)]"
        />
      </Button>
    </div>
  );
};

export default FilesList;
