import React, { useEffect, useState, useRef } from "react";
import {
  Card,
  Checkbox,
  Col,
  Flex,
  Input,
  Modal,
  notification,
  Skeleton,
  Spin,
} from "antd";
import SubMenu from "../../Components/SubMenu";
import { useTranslation } from "react-i18next";
import AppInfo from "../../Components/AppInfo";
import LeftSideTitlePart from "../../Components/HeaderParts/LeftSideTitlePart";
import IconsAll from "../../Components/IconsAll";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { themes } from "../../config";
import Paragraph from "antd/es/typography/Paragraph";
import {
  MdDeleteOutline,
  MdOutlineCreateNewFolder,
  MdOutlineEdit,
} from "react-icons/md";
import { FaRegFolder } from "react-icons/fa";
import axiosInstance from "../../helpers/axios";
import Apis from "../../helpers/Apis";
import { store } from "../../Redux/store";
import { Link, useParams } from "react-router-dom";
import Title from "antd/es/typography/Title";
import CustomDragger from "../../Components/DynamicForm/Components/CustomDragger";
import RightSIdeTitlePart from "../../Components/HeaderParts/RightSIdeTitlePart";
import { setSelectedFolderData } from "../../Redux/slices/media.reducer";

const GebruikerSort = styled(Flex)`
  border-radius: 8px;
  border: 1px solid hsla(var(--input-field-border-color), 0.25);
  background: ${() =>
    themes[useSelector((state) => state.theme.name)].token
      .gebruikersTableSortBg};
  box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.04);
  backdrop-filter: blur(2px);
  position: sticky;
  top: 10px;
  z-index: 1;
  padding: 13px 24px;
  align-items: center;
  justify-content: space-between;

  .ant-checkbox {
    .ant-checkbox-inner {
      border-radius: 100vh;
      border: 1px solid #88909a;
    }

    + span {
      color: #88909a;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
    }
  }

  .email-sort-button {
    &:hover,
    &:active,
    &:focus-visible {
      background-color: transparent !important;
    }
  }
`;

const DocumentCard = styled(Card)`
  border: none;
  max-width: 160px;
  position: relative;
  background-color: transparent;

  &:hover {
    .dock-checkbox {
      opacity: 1;
    }
  }

  .dock-checkbox {
    position: absolute;
    top: 5px;
    left: 5px;
    transition: 0.3s ease-in-out;
    z-index: 1;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
  }

  .ant-card-body {
    padding: 0;

    .doc-image {
      width: 160px;
      height: 160px;
      border-radius: 10px;
      margin-bottom: 5px;
      overflow: hidden;
      background-color: ${() =>
        themes[useSelector((state) => state.theme.name)].components.Menu
          .itemBg};
      display: flex;
      align-items: center;
      justify-content: center;

      > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
      }

      > svg {
        width: 50px;
        height: 50px;
        object-fit: contain;
      }
    }

    .folder-info {
      list-style: none;
      margin-bottom: 0;
      display: flex;
      font-size: 14px;
      gap: 5px;

      li {
        margin: 0;
        padding: 0;
      }
    }
  }

  &:has(.ant-checkbox-checked) {
    .doc-image {
      > img {
        transform: scale(0.9);
      }
    }
  }
`;

const findFolderById = (folders, folderId) => {
  for (const folder of folders) {
    if (folder.key === folderId) {
      return folder;
    }
    if (folder.children) {
      const found = findFolderById(folder.children, folderId);
      if (found) {
        return found;
      }
    }
  }
  return null;
};

const FileCard = ({ file, selectedFiles, setSelectedFiles, allowMultiple }) => {
  const [imageUrl, setImageUrl] = useState(null);

  const getDisplayImage = async (img_id) => {
    if (!["image/jpg", "image/jpeg", "image/png"].includes(file?.type))
      return null;
    try {
      const resp2 = await axiosInstance.get(Apis.GET_DISPLAY_IMAGE(img_id), {
        headers: {
          "x-tenant-id": store.getState().user.userId,
        },
        responseType: "blob",
      });

      if (resp2.status === 200) {
        setImageUrl(URL.createObjectURL(resp2.data));
      }
    } catch (error) {
      console.log("error", error);
      // notification.error({
      //   message: "Something went wrong while uploading the file.",
      // });
      return null;
    }
  };

  useEffect(() => {
    getDisplayImage(file?._id);
  }, [file?._id]);

  return (
    <DocumentCard key={file?._id}>
      <Checkbox
        onChange={(e) =>
          setSelectedFiles((prev) =>
            allowMultiple
              ? e.target.checked
                ? [...prev, file?._id]
                : prev?.filter((p) => p !== file?._id)
              : [file?._id],
          )
        }
        checked={selectedFiles?.includes(file?._id)}
        className={`dock-checkbox ${!selectedFiles?.length ? "opacity-0" : "opacity-100"}`}
      ></Checkbox>
      <div className="doc-image">
        <img src={imageUrl} alt="image" />
        {/* <IconsAll.AdministratorIcon /> */}
      </div>
      <Paragraph className="!mb-1 font-bold">{file?.title}</Paragraph>
      <Paragraph>
        <ul className="folder-info">
          <li>{file?.filename_disk?.split(".")?.pop()}</li>
          <li>.</li>
          <li>{file?.file_size} kB</li>
        </ul>
      </Paragraph>
    </DocumentCard>
  );
};
const Media = ({ updateContentFilesList, setShowDrawer, iFace }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedFolderData = useSelector(
    (state) => state.media.selectedFolderData,
  );
  const { folderId } = useParams();

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [subMenus, setSubMenus] = useState([]);
  const [newFolderName, setNewFolderName] = useState("");
  const [isFileModalOpen, setIsFileModalOpen] = useState(false);
  const [filesList, setFilesList] = useState([]);
  const [fileListLoading, setFileListLoading] = useState(false);
  const [folderModal, setFolderModal] = useState({
    type: "Create",
    isOpen: false,
    loading: false,
  });
  const [defaultOpenedKeys, setDefaultOpenedKeys] = useState([]);

  useEffect(() => {
    getFoldersList();
  }, []);

  useEffect(() => {
    getFilesList();
  }, [selectedFolderData?._id]);

  const getFilesList = async () => {
    try {
      setFileListLoading(true);
      const resp = await axiosInstance.get(
        Apis.GET_FILES(selectedFolderData?._id || ""),
        {
          headers: {
            "x-tenant-id": store.getState().user.userId,
          },
        },
      );
      if (resp.status === 200) setFilesList(resp.data?.payload?.data);
    } catch (error) {
      console.log("Error", error);
    } finally {
      setFileListLoading(false);
    }
  };

  const updateFilesList = (newFiles) => {
    setFilesList((prev) => {
      return [...prev, ...newFiles];
    });
  };

  const CustomDropdown = ({ title, indentation = 0, link, folderData }) => {
    const dispatch = useDispatch();
    const selectedFolderData = useSelector(
      (state) => state.media.selectedFolderData,
    );

    const [dropDownVisible, setIsDropdownVisible] = useState(false);
    const dropDownPadding = 20;
    const dropdownRef = useRef(null);
    const [dropdownListClient, setDropdownListClient] = useState({
      X: 0,
      Y: 0,
    });

    const handleRightClick = (event) => {
      if (!window.location.pathname.includes("media-library")) return;
      event.preventDefault();
      setIsDropdownVisible(true);
      setDropdownListClient({ X: event.clientX + 10, Y: event.clientY - 10 });
    };

    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownVisible(false);
      }
    };

    useEffect(() => {
      if (dropDownVisible) {
        document.addEventListener("mousedown", handleClickOutside);
      } else {
        document.removeEventListener("mousedown", handleClickOutside);
      }

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [dropDownVisible]);

    return (
      <div
        className={`flex items-center justify-between ${
          folderData._id === selectedFolderData?._id
            ? "custom-selected-submenu"
            : ""
        }`}
        onContextMenu={handleRightClick}
      >
        <div
          className="edit-delete-wrapper"
          ref={dropdownRef}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Link
            to={!iFace ? link : ""}
            className="block"
            onClick={() => dispatch(setSelectedFolderData(folderData))}
            onContextMenu={(e) => {
              e.preventDefault();
              dispatch(setSelectedFolderData(folderData));
            }}
            style={{ paddingLeft: dropDownPadding * indentation + "px" }}
          >
            <div className="flex items-center gap-3">
              <FaRegFolder size={24} className="flex-shrink-0" /> {title}
            </div>
          </Link>
          {dropDownVisible && (
            <ul
              style={{ left: dropdownListClient.X, top: dropdownListClient.Y }}
              className="edit-delete-dropdown"
            >
              <li
                onClick={() => {
                  setFolderModal({
                    type: "Rename",
                    isOpen: true,
                    loading: false,
                  });
                  setNewFolderName(folderData?.name);
                }}
                className="flex items-center gap-2"
              >
                <MdOutlineEdit size={20} className="flex-shrink-0" />
                rename
              </li>
              <li
                className="flex items-center gap-2 !text-[rgba(var(--btn-delete))]"
                onClick={() => {
                  setFolderModal({
                    type: "Delete",
                    isOpen: true,
                    loading: false,
                  });
                }}
              >
                <MdDeleteOutline
                  size={20}
                  className="flex-shrink-0 !text-[rgba(var(--btn-delete))]"
                />
                delete
              </li>
            </ul>
          )}
        </div>
        <div className="w-[45px]"></div>
      </div>
    );
  };

  const getFoldersList = async () => {
    try {
      let response = await axiosInstance.get(Apis.GET_ALL_FOLDERS(), {
        headers: {
          "x-tenant-id": store.getState().user.userId,
        },
      });
      const foldersData = [
        {
          name: (
            <div onClick={() => dispatch(setSelectedFolderData({}))}>
              {t("File") + " " + t("Library")}
            </div>
          ),
          link: "/media-library",
          key: "root-folder",
          icon: <MdOutlineCreateNewFolder size={24} />,
          children: [],
        },
      ];
      foldersData[0].children = transformData(response?.data?.payload?.data);

      const openkeysCalculation = (folders = [], initialKey) => {
        let parentFolderId = initialKey;
        const parentIds = [];
        while (parentFolderId) {
          const currentFolderData = folders.find((item) => {
            return item._id === parentFolderId;
          });
          if (currentFolderData?._id) {
            parentIds.push(currentFolderData._id);
          }
          parentFolderId = currentFolderData?.parent;
        }
        setDefaultOpenedKeys(["root-folder", ...parentIds]);
      };
      if (folderId && folderId !== selectedFolderData?._id) {
        const folderData = findFolderById(
          foldersData[0]?.children || [],
          folderId,
        );
        if (folderData?.key) {
          dispatch(
            setSelectedFolderData(folderData?.label?.props?.folderData || {}),
          );
          openkeysCalculation(response?.data?.payload?.data, folderData.key);
        }
      } else {
        openkeysCalculation(
          response?.data?.payload?.data,
          selectedFolderData._id,
        );
      }
      setSubMenus(foldersData);
    } catch (error) {
      console.log("Error", error);
    }
  };

  const transformData = (folders) => {
    const buildHierarchy = (parentId) => {
      return folders
        .filter((folder) => folder.parent === parentId)
        .map((folder) => {
          const children = buildHierarchy(folder._id);
          return {
            _id: folder._id,
            name: folder.name,
            link: "/media-library/" + folder._id,
            children: children.length > 0 ? children : undefined,
          };
        });
    };

    const hierarchicalData = buildHierarchy(null);

    const transformFolder = (folder, indentation) => {
      const transformedItem = {
        key: folder._id,
        indentation,
        label: (
          <CustomDropdown
            title={folder.name}
            indentation={indentation}
            link={"/media-library/" + folder._id}
            folderData={folder}
          />
        ),
      };

      if (folder.children) {
        transformedItem.children = folder.children.map((child) =>
          transformFolder(child, indentation + 1),
        );
      }
      return transformedItem;
    };
    return hierarchicalData.map((folder) => transformFolder(folder, 2));
  };

  const handleFoldarModalOk = async (modalType) => {
    try {
      let folderName = "";
      let apiCall;
      setFolderModal({ type: modalType, isOpen: true, loading: true });

      switch (modalType) {
        case "Create":
          folderName = newFolderName.trim();
          if (!folderName.length) return;
          apiCall = axiosInstance.post(
            Apis.CREATE_FOLDER(),
            { name: folderName, parent: selectedFolderData?._id || null },
            { headers: { "x-tenant-id": store.getState().user.userId } },
          );
          break;

        case "Rename":
          folderName = newFolderName.trim();
          if (!folderName.length || folderName === selectedFolderData?.name)
            return;
          apiCall = axiosInstance.put(
            Apis.UPDATE_FOLDER(selectedFolderData._id),
            { name: folderName },
            { headers: { "x-tenant-id": store.getState().user.userId } },
          );
          break;

        case "Delete":
          apiCall = axiosInstance.delete(
            Apis.DELETE_FOLDER(selectedFolderData._id),
            {
              headers: { "x-tenant-id": store.getState().user.userId },
            },
          );
          break;
        default:
          throw new Error(`Unknown action type: ${modalType}`);
      }
      const response = await apiCall;
      if (response.status === 200) {
        notification.success({ message: `Folder ${modalType}d successfully.` });
        getFoldersList();
      }
    } catch (error) {
      console.error(`Error during folder ${modalType}:`, error);
    } finally {
      setNewFolderName("");
      setFolderModal({ type: modalType, isOpen: false, loading: false });
    }
  };

  const handleFilesDelete = async () => {
    Modal.confirm({
      title: `Delete Files`,
      content: `Are you sure you want to delete the selected files permanently?This action is permanent.`,
      icon: null,
      okText: "Delete",
      okType: "primary",
      cancelText: "Cancel",
      maskClosable: true,
      // className: "modal-delete",
      onOk: async () => {
        try {
          const resp = await axiosInstance.put(
            Apis.DELETE_FILE(),
            { ids: selectedFiles },
            {
              headers: {
                "x-tenant-id": store.getState().user.userId,
              },
            },
          );
          if (resp.status === 200) {
            notification.success({ message: "Files deleted successfully" });
            setFilesList((prev) => {
              return prev?.filter((f) => !selectedFiles?.includes(f?._id));
            });
            setSelectedFiles([]);
          }
        } catch (error) {
          notification.error({ message: "Files not deleted" });
        }
      },
    });
  };

  const handleSelect = () => {
    updateContentFilesList(
      filesList?.filter((file) => selectedFiles?.includes(file?._id)),
    );
    setShowDrawer(false);
  };

  return (
    <Flex className="w-full main-content-wrap">
      {subMenus.length > 0 ? (
        <SubMenu
          isSubMenuLogo={true}
          isSubMenuVersion={true}
          items={subMenus}
          defaultOpenedKeys={defaultOpenedKeys}
          from={"media"}
        />
      ) : (
        <div className="w-[260px] fixed top-0 bottom-0 z-50 lg:min-h-screen modal-sidebar-media p-8">
          <Skeleton></Skeleton>
        </div>
      )}
      <div id="left-part" className="px-4 xl:px-16 w-full left-part">
        <div className="flex flex-wrap md:items-center justify-between mt-9 mb-11">
          <LeftSideTitlePart
            pageIcon={true}
            pageInnerIcon={
              <IconsAll.MediaLibraryIcon strokeColor={"#3586c7"} />
            }
            pageTitle={selectedFolderData?.name || "File Library"}
            pageSubTitle={"File Library"}
          />
          <RightSIdeTitlePart
            addButton={!iFace}
            handleAddClick={() => setIsFileModalOpen(true)}
            deleteButton={!iFace}
            deleteDisabled={!selectedFiles?.length}
            handleDeleteClick={handleFilesDelete}
            folderButton={!iFace}
            showButton={iFace}
            handleShowClick={handleSelect}
            handleFolderClick={() => {
              setFolderModal({
                type: "Create",
                isOpen: true,
                loading: false,
              });
              setNewFolderName("");
            }}
          />
          {/* <===================== File Modal =====================> */}
          <Modal
            title="Add File"
            open={isFileModalOpen}
            onOk={() => setIsFileModalOpen(false)}
            onCancel={() => setIsFileModalOpen(false)}
          >
            <CustomDragger
              iFace={`files-page`}
              setIsModalOpen={setIsFileModalOpen}
              updateFilesList={updateFilesList}
              folder={selectedFolderData?._id}
            />
          </Modal>
          {/* <===================== Commmon Folder Modal =====================> */}
          <Modal
            title={folderModal.type + " Folder"}
            open={folderModal.isOpen}
            onOk={() => handleFoldarModalOk(folderModal.type)}
            onCancel={() =>
              setFolderModal({
                type: folderModal.type,
                isOpen: false,
                loading: false,
              })
            }
            loading={folderModal.loading}
            destroyOnClose
          >
            <p className="mb-3">
              {folderModal.type === "Delete" ? (
                <div>
                  Are you sure you want to delete {selectedFolderData?.name}{" "}
                  folder?
                </div>
              ) : (
                <Input
                  placeholder="Name"
                  className="min-h-14 rounded-xl placeholder:font-normal px-6"
                  value={newFolderName}
                  onChange={(e) => setNewFolderName(e.target.value)}
                />
              )}
            </p>
          </Modal>
        </div>
        <GebruikerSort className="mb-8">
          <Checkbox
            onChange={(e) =>
              setSelectedFiles(
                e.target.checked ? filesList?.map((f) => f?._id) : [],
              )
            }
            disabled={["file", "file-image"].includes(iFace)}
            checked={
              selectedFiles?.length > 0 &&
              selectedFiles?.length === filesList?.length
            }
          >
            Select All
          </Checkbox>
          {/* <Button className="email-sort-button | flex items-center gap-4 text-left border-0 email-sort bg-transparent dark:bg-inputField">
            <span>
              <span className="block text-sm text-slate-400">
                {t("sort") + "  " + t("on")}:
              </span>
              <p className="block text-base text-slate-600">Upload Date</p>
            </span>
            <BiSortAlt2 size={20} />
          </Button> */}
        </GebruikerSort>
        {fileListLoading ? (
          <Flex
            className="items-center justify-center"
            style={{ minHeight: "calc(100% - 150px)" }}
          >
            <Spin />
          </Flex>
        ) : filesList?.length ? (
          <Flex gap="middle" align="start" wrap="wrap">
            {filesList?.map((file) => {
              return (
                <FileCard
                  key={file?._id}
                  file={file}
                  selectedFiles={selectedFiles}
                  setSelectedFiles={setSelectedFiles}
                  allowMultiple={!["file", "file-image"].includes(iFace)}
                />
              );
            })}
          </Flex>
        ) : (
          <Flex
            className="items-center justify-center"
            style={{ minHeight: "calc(100% - 150px)" }}
          >
            <Flex className="text-center" vertical>
              <div className="w-20 h-20 rounded-full bg-grey-500 mx-auto mb-4 flex items-center justify-center">
                <FaRegFolder size={24} />
              </div>
              <Title className="!mb-0">No Files</Title>
              <Paragraph>There are no files here. </Paragraph>
            </Flex>
          </Flex>
        )}
      </div>
      <Col className="right-part">
        <AppInfo />
      </Col>
    </Flex>
  );
};

export default Media;
